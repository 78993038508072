import { Amplify } from 'aws-amplify';

// AWS
const identityPoolId = 'eu-west-1:15773cb2-9a40-4632-bade-2867b4a4f8b3';
const region = 'eu-west-1';
const userPoolId = 'eu-west-1_t5HV3bFjl';
const userPoolWebClientId = '23s0olnnniu5472ons0d9uoqt9';

Amplify.configure({
    Auth: {
        identityPoolId,
        region,
        userPoolId,
        userPoolWebClientId,
        mandatorySignIn: true,
    },
});
