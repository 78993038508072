const palette = {
    //
    // Primary palette
    dark: '#303030',
    light: '#ADADAD',
    //
    red: '#FC440F',
    // darkRed: '#C6141F',
    lightRed: '#F7C1BB',
    //
    blue: '#5386E4',
    light_blue: '#326789',
    //
    green: '#A5D447',
    lightGreen: '#2AC71E',

    darkGreen: '#5B8E7D',
    andersen_dark_green: '#2B5F4D',
    andersen_light_green: '#8CB5A8',
    //
    grey: '#3A3B3D',
    dark_grey: '#6C7477',
    light_grey: '#9A9A9A',
    lighter_grey: '#F2F2F2',
    //
    yellow: '#FFBC42',
    orange: '#DFA131',
    //
    // UI States
    error: '#BD2848',
    warning: '#FAAF24',
    success: '#43AF6B',
    //
    // Basics
    offWhite: '#F8F7F3',
    // white: '#FFF8F0',
    white: '#FCFCFC',
    brightWhite: '#FFFFFF',
    black: '#212121',
    brightBlack: '#000000',
    //
    // Special
    surface: '#5F5F5F',
    surface_40: '#5F5F5F66',
    title: '#8B9DA3',

    //
    dividerColor: '#3c3c3c',
    transparent: '#00000000',
    offRed: '#ffcccc',
    lightOffRed: '#ff9999',

    //
    // Icons
    moonlightYellow: '#e1c38b',
    sunshineOrange: '#ff9900',

    //
    // Andersen
    andersen_black: '#3A3A3C',
    andersen_black_60: '#3A3A3C99',
    andersen_black_50: '#3A3A3C80',
    andersen_black_40: '#3A3A3C66',
    andersen_black_30: '#3A3A3C4C',
    andersen_black_20: '#3A3A3C33',
    andersen_black_10: '#3A3A3C19',
    //
    andersen_white: '#FFFFFF',
    andersen_white_60: '#FFFFFF99',
    andersen_white_50: '#FFFFFF80',
    andersen_white_40: '#FFFFFF66',
    andersen_white_30: '#FFFFFF4C',
    andersen_white_20: '#FFFFFF33',
    andersen_white_10: '#FFFFFF19',
    //
    andersen_dark_blue: '#2A555A',
    andersen_stone: '#A0A091',
    andersen_maroon: '#663C44',
    andersen_light_blue: '#4E6773',
    andersen_light_stone: '#E0DED3',
    andersen_light_grey: '#808282',
    andersen_green: '#5B8E7D',
    //
    // Unit colors
    side_jet_black: '#101011',
    side_laurac_sand: '#B4ADA2',
    side_malmo_blue: '#485C67',
    side_nearly_black: '#464647',
    side_putty_stone: '#DBD8CD',
    side_ruby: '#57373C',
    side_southbank_grey: '#787671',
    side_stanton_green: '#9C9E93',
    side_steel: '#757575',
    //
    primary: '#3A3A3C',
    accent: '#5B8E7D',
    secondary: '#A0A091',
    //
    mixed: '#ada560',
};

export default palette;
