import { createTheme } from '@mui/material/styles';

import palette from './_palette';

// Create a theme instance.
const theme = createTheme({
    typography: {
        fontFamily: "'Mulish'",
        htmlFontSize: 16,
        button: {
            fontWeight: 500,
            textTransform: 'none',
            fontSize: 14,
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                outlined: {
                    borderColor: palette.andersen_white_20,
                    ':hover': {
                        borderColor: `${palette.lightRed} !important`,
                    },
                },
                root: {
                    color: palette.white,
                    borderColor: palette.andersen_green,
                },
            },
        },
    },
    shape: {
        borderRadius: 10,
    },
    palette: {
        mode: 'dark',
        common: {
            white: palette.white,
            black: palette.black,
        },
        text: {
            primary: palette.white,
            secondary: palette.andersen_white_50,
            disabled: palette.dark_grey, // no transparency
        },
        background: {
            default: palette.black,
            paper: palette.dark,
        },
        primary: {
            main: palette.andersen_green,
        },
        secondary: {
            main: palette.andersen_dark_blue,
        },
        error: {
            main: palette.error,
        },
        success: {
            main: palette.success,
        },
    },
});

export default theme;
